import React, { FunctionComponent } from 'react';
import { graphql } from 'gatsby';
import { PostPageItemType } from '../types/PostItem.types';
import styled from '@emotion/styled';

import Template from 'components/common/Template';
import Header from 'components/common/Header';
import PostHead from 'components/PostHead';
import PostContent from 'components/PostContent';
import Footer from 'components/common/Footer';
import Bio from 'components/common/Bio';
import Comment from 'components/Comment';
import { globalStyles } from '../styles/GlobalStyle';

type PostTemplateProps = {
  data: {
    allMdx: {
      edges: PostPageItemType[];
    };
  };
  location: {
    href: string;
  };
};

const StyledPostBio = styled.section`
  margin: 0 auto;
  display: flex;
  justify-content: center;

  section {
    margin-top: 0;
    div {
      padding: calc(var(--inner-primary) * 2);
    }
  }

  @media (max-width: ${globalStyles.mobile}) {
    section {
      div {
        padding: calc(var(--inner-secondary) * 2);
      }
    }
  }
`;

const PostTemplate: FunctionComponent<PostTemplateProps> = function ({
  data: {
    allMdx: { edges },
  },
  location: { href },
}) {
  const {
    node: {
      body,
      frontmatter: { title, summary, date, lastMod, categories },
    },
  } = edges[0];

  return (
    <Template
      title={title}
      description={summary}
      url={href}
      date={date}
      lastMod={lastMod}
    >
      <Header />
      <PostHead
        title={title}
        date={date}
        lastMod={lastMod}
        categories={categories}
      />
      <PostContent body={body} />
      {/* <StyledPostBio>
        <Bio />
      </StyledPostBio> */}
      <Comment />
      <Footer />
    </Template>
  );
};

export default PostTemplate;

export const queryMarkdownDataBySlug = graphql`
  query queryMarkdownDataBySlug($slug: String) {
    allMdx(filter: { fields: { slug: { eq: $slug } } }) {
      edges {
        node {
          body
          frontmatter {
            title
            summary
            date(formatString: "DD MMM YYYY")
            lastMod(formatString: "DD MMM YYYY")
            categories
            thumbnail {
              publicURL
            }
          }
        }
      }
    }
  }
`;
